import React, { type FC, Fragment, type ChangeEvent } from 'react';
import { type BidEntry, type Bid } from './BidEntry';
import { observer } from 'mobx-react';
import '../../CurveTable/curve-table.scss'
import { Multiplier } from '../../../Util';
import { parse, format } from 'date-fns';


export interface ColumnConfig {
  label: string;
  key: keyof Bid;
  multiplier?: number;
}

const columns: ColumnConfig[] = [
  { label: "MW Change", key: "minChange" },
  { label: "Quantity", key: "quantity", multiplier: Multiplier.MW },
  { label: "Limit", key: "limit", multiplier: Multiplier.EUR },
];

// Return formatted Product ID
const formatProductId = (value: string): string => {
  const parts = value?.split('-');
  const firstPart = parts?.[0];
  const thirdPart = parts?.[2];

  return `${firstPart || ''}-${thirdPart || ''}`;
};

const getDateFromProductId = (productId: string): string => {
  const parts = productId.split('-');
  const parsedDate = parse(parts[1], 'yyyyMMdd', new Date());
  return parsedDate ? format(parsedDate, 'dd/MM/yyyy') : '';
};

interface TableProps {
  data: BidEntry[];
  modifyCell: (productId: string, bidIndex: number, key: keyof Bid, value: number) => void;
  isViewing: boolean;
}


const BidTable: FC<TableProps> = observer(({ data, modifyCell, isViewing }) => {
  const sortedData = [...data].sort((a, b) =>
    a.productId.localeCompare(b.productId)
  );

  const numColumns = 1 + data[0]?.bids.length * columns.length;
  const gridStyle = {
    '--columns': numColumns,
    '--rows': data.length + 1, // +1 for header
    height: `${(data.length + 1) * 33}px`,
  } as React.CSSProperties;

  return (
    <div className="grid-table" style={gridStyle}>

      {/* Headers */}
      <div className="h l cell">
        Product ID - {getDateFromProductId(sortedData[0].productId)}
      </div>
      {sortedData[0]?.bids.map((_, bidIndex) =>
        columns.map((col) => (
          <div key={`header-${col.key}-${bidIndex}`} className="h cell">
            {col.label} {bidIndex + 1}
          </div>
        ))
      )}

      {/* Data Rows */}
      {sortedData.map((bidEntry, rowIndex) => (
        <Fragment key={`row-${rowIndex}`}>
          <div className="l cell">{formatProductId(bidEntry.productId)}</div>

          {bidEntry.bids.map((bid, bidIndex) =>

            columns.map((col) => (
              <div key={`cell-${rowIndex}-${bidIndex}-${col.key}`}
                className="cell">

                <input
                  className="input-field"
                  type="number"
                  value={bid[col.key] !== undefined && col.multiplier
                    ? bid[col.key] / col.multiplier : bid[col.key]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const inputValue = Number(e.target.value);
                    const adjustedValue = col.multiplier
                      ? inputValue * col.multiplier : inputValue;
                    modifyCell(bidEntry.productId, bidIndex, col.key, adjustedValue);
                  }}
                  disabled={isViewing}
                />

              </div>
            ))
          )}
        </Fragment>
      ))}
    </div>
  );
});


export default BidTable;
