import { observer } from "mobx-react";
import { useStores } from "../../../use-stores";
import { type FC, useState } from "react";
import { type Bid, type BidEntry } from "./BidEntry";
import BidTable from "./BidTable";
import { type Product } from "../../../classes/Product";
import { TZDate } from "@date-fns/tz";
import { addDays, subHours, startOfDay } from "date-fns";
import { type SpotPrice } from "../../../classes/SpotPrice";
import './product-modal.scss';

interface ModalProps {
  onChange: (updatedBids: BidEntry[]) => void;
  state: BidEntry[];
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

function getBiddingWindow(): { tomorrow: Date; endOfWindow: Date } {
  const timeZone = "Europe/Copenhagen";
  const now = new Date();
  const tzDate = new TZDate(now, timeZone);
  const tomorrow = startOfDay(addDays(tzDate, 1));
  const dayAfterTomorrow = addDays(tomorrow, 1);
  const endOfWindow = subHours(dayAfterTomorrow, 2);

  return { tomorrow, endOfWindow };
}

function filterProducts(products: Product[]): Product[] {
  const { tomorrow, endOfWindow } = getBiddingWindow();
  const timeZone = "Europe/Copenhagen";

  return products.filter((product) => {
    const productDeliveryDate = new TZDate(
      new Date(product.deliveryStart),
      timeZone
    );
    return productDeliveryDate >= tomorrow && productDeliveryDate <= endOfWindow;
  });
}


function generateBids(
  products: Product[],
  bidState: BidState,
  getSpotPrice: (eicCode: string, deliveryStart: string) => SpotPrice | undefined
): Bid[] {
  return products.map((product) => {

    const eicCodeDK1 = '10YDK-1--------W';
    let currentLimit = bidState.limit;
    const spotPrice = getSpotPrice(eicCodeDK1, product.deliveryStart);

    if (spotPrice && spotPrice.price !== undefined) {
      currentLimit = spotPrice.price + (bidState.bidEntryIndex * -500);
    }
    
    const bid: Bid = {
      minChange: bidState.minChange,
      quantity: bidState.quantity,
      limit: currentLimit,
    };
    return bid;
  });
}

export interface ColumnConfig {
  label: string;
  key: keyof Bid;
  modifiable?: boolean;
  multiplier?: number;
}

const initialBidState = {
  minChange: 800,
  quantity: 0,
  limit: 0,
  bidEntryIndex: 0,
};

interface BidState {
  minChange: number;
  quantity: number;
  limit: number;
  bidEntryIndex: number;
}


const ProductModal: FC<ModalProps> = observer(({ onChange, setShowModal, showModal, state }) => {
  const { configStore, spotStore } = useStores();
  const { getSpotPrice } = spotStore;
  const { products } = configStore;
  const filteredProducts = filterProducts(products);
  const [bidState, setBidState] = useState<BidState>(initialBidState);

  const [tableData, setTableData] = useState<BidEntry[]>(() => initializeTableData(bidState));
  const [isViewing, setIsViewing] = useState<boolean>(false);
  function initializeTableData(bidState: BidState): BidEntry[] {
    const initialBids = generateBids(
      filteredProducts,
      bidState,
      getSpotPrice
    );

    return filteredProducts.map((product, index) => ({
      productId: product.name,
      deliveryStart: product.deliveryStart,
      contractId: product.contractId,
      bids: [initialBids[index]],
    }));
  }
  
  // Modify cell function
  const modifyCell = (
    productId: string,
    bidIndex: number,
    key: keyof Bid,
    value: number
  ) => {
    setTableData((prevData) => {

      // Spread the previous state
      const prev = [...prevData];
      const bidEntryIndex = prev.findIndex(
        (entry) => entry.productId === productId
      );
      const bidEntry = { ...prev[bidEntryIndex] };
      const bids = [...bidEntry.bids];
      const bid = { ...bids[bidIndex] };

      // Update the desired key in the bid
      bid[key] = value;
      bids[bidIndex] = bid;
      bidEntry.bids = bids;
      prev[bidEntryIndex] = bidEntry;

      return prev;
    });
  };

  // Handle saving the data
  function handleSaveClick() {
    onChange(tableData);
    setShowModal(false);
  }

  function handleResetClick() {
    setBidState(initialBidState);
    const newTableData = initializeTableData(initialBidState);
    setTableData(newTableData);
  }

  // Handle adding new bids
  function handleAddClick() {
    const updatedBidState: BidState = {
      ...bidState,
      minChange: bidState.minChange + 500,
      bidEntryIndex: bidState.bidEntryIndex + 1,
    };

    setBidState(updatedBidState);

    const newBids = generateBids(
      filteredProducts,
      updatedBidState,
      getSpotPrice
    );

    // Update the table data
    setTableData((prevData: BidEntry[]) => {
      return prevData.map((bidEntry, index) => ({
        ...bidEntry,
        bids: [...bidEntry.bids, newBids[index]],
      }));
    });
  }

  return (
    <div>
      {showModal && (
        <div className="modal">
          <div className="modal-main-content">
            <div className="modal-table">
              <BidTable data={tableData} modifyCell={modifyCell} isViewing={isViewing} />
              <div className="modal-navigation">
                <button
                  disabled={isViewing || bidState.bidEntryIndex === 2}
                  onClick={handleAddClick}
                >
                  Add
                </button>
                
                <button
                  disabled={isViewing}
                  onClick={handleResetClick}
                >
                  Reset
                </button>
                <button
                  disabled={isViewing}
                  onClick={handleSaveClick}
                >
                  Save
                </button>
                <button onClick={() => {
                  setShowModal(false)
                  setIsViewing(false)
                  handleResetClick()
                }}>Close</button>

              </div>
            </div>
          </div>
        </div>
      )}
      <div className='field-group bids-group'>
        <p>
          View
        </p>
        <button
          onClick={(e) => {
            if (state?.[0]?.bids?.length > 0) {
              setTableData(state);

              // Updating the bidEntryIndex to bidEntries from the state
              setBidState((prevState) => ({
                ...prevState,
                bidEntryIndex: state[0].bids.length - 1,
              }));

              setIsViewing(true);
              setShowModal(true);
            }}
          }
        >
          View
        </button>
      </div>

    </div>
  );
});

export default ProductModal;
