import React, { type FunctionComponent, type MouseEvent, useEffect, useState } from 'react'
import './portfolioTable.scss'
import { type Product } from '../../classes/Product'
import { observer } from 'mobx-react'
import { useStores } from '../../use-stores'
import { type StrategyInvocation } from '../../classes/Order'
import { id, type Portfolio } from '../../classes/Portfolio'
import { formatDictionary, TooltipText } from '../Tooltip/Tooltip'
import { lowerCaseAllButFirst, splitPascalCase } from '../Executor/Executor'
import { productToShortName } from '../../Util'
import { IoMdSettings } from 'react-icons/io'
import useKeyDownListener from '../../hooks/useKeyDownListener'

interface TableProps {
  rows: Product[]
  productId?: string
  setProductId: (prodId?: string) => void
}

// Max int for checking ask price
export const max = 2147483647
// Min int for checking bid price
export const min = -2147483648

const formatPrice = (state: string, price: number, bid: boolean) => {
  if (bid) {
    return price === min
      ? '-'
      : (price / 100.0).toLocaleString('dk-DA', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
  } else {
    return price === max
      ? '-'
      : (price / 100.0).toLocaleString('dk-DA', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
  }
}

// Check if product has some active or waiting strategy attached to it.
const hasStrategy = (
  buy: boolean,
  strategies: StrategyInvocation[],
  product: Product,
  portfolio?: Portfolio
) =>
  portfolio !== undefined
    ? strategies.findIndex(
        (s) =>
          s.invocation.buy === buy &&
          id(s.portfolio) === id(portfolio) &&
          s.invocation.contractId === product.contractId &&
          (s.state === 'ACTIVE' || s.state === 'WAITING')
      ) > -1
    : false

const Table: FunctionComponent<TableProps> = observer(({ rows, productId, setProductId }) => {
  const { uiStore, configStore, orderStore, spotStore } = useStores()

  const { strategies } = orderStore
  const { pickedProducts, getPortfolio } = configStore
  const { showSpotPrices } = uiStore

  const [mouse, setMouse] = useState<MouseEvent | undefined>(undefined)
  const [tooltip, setTooltip] = useState<string | undefined>(undefined)

  // Product type selector
  const [selectorOpen, setSelectorOpen] = useState(false)

  useEffect(() => {
    setSelectorOpen(false)
    setProductId(undefined)
  }, [getPortfolio])

  /** Pick a contract. If ctrl is pressed, we add it to picked products */
  const chooseContract = (id: string) => (event: React.MouseEvent<HTMLTableRowElement>) => {
    if (event.ctrlKey) {
      configStore.setPickedProducts(id)
    } else {
      configStore.setProduct(id)
    }
  }

  const getSpotPrice = (eicCode: string | undefined, deliveryStart: string) => {
    if (eicCode !== undefined) {
      const price = spotStore.getSpotPrice(eicCode, deliveryStart)
      return price != null
        ? (price.price / 100.0).toLocaleString('dk-DA', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        : '-'
    }
  }

  // This function finds all strategies invoked on specific product, and creates one large text block from them
  const createTooltipText = (product: Product, buy: boolean) => {
    const portfolio = getPortfolio
    if (portfolio === undefined) setTooltip(undefined)
    else {
      const strategiesForProduct = strategies.filter(
        (s) =>
          s.invocation.buy === buy &&
          id(s.portfolio) === id(portfolio) &&
          s.invocation.contractId === product.contractId &&
          (s.state === 'ACTIVE' || s.state === 'WAITING')
      )
      if (strategiesForProduct.length < 1) setTooltip(undefined)
      else {
        // Create text snippets containing strategy name and parameters
        const texts = strategiesForProduct.map(
          (s) =>
            lowerCaseAllButFirst(splitPascalCase(s.type).join(' ')) +
            '\n' +
            formatDictionary(s.invocation.variables, s.invocation.buy)
        )

        setTooltip(texts.join('\n\n'))
      }
    }
  }

  // Create the table rows in order and cutoff at size limit
  const tableRows = rows.slice()
    .sort((a, b) => a.deliveryStart.localeCompare(b.deliveryStart))
    .slice(0, uiStore.hoursAhead)


  // Use this key down listener for multiple contracts.
  useKeyDownListener(['ArrowUp', 'ArrowDown'], (e) => {
    if (!e.ctrlKey) return

    if (e.key === 'ArrowUp') {
      // Find first picked index. Pick contract right above if possible.
      const index = tableRows
        .findIndex(row => pickedProducts.some(p => row.contractId === p.contractId))

      if (index > 0 ) configStore.setPickedProducts(tableRows[index - 1].contractId)

    }
    else {
      // Find last picked index. Pick contract right below if possible.
      let index: number | undefined
      tableRows.forEach((row, idx) => {
        if (pickedProducts.some(p => row.contractId === p.contractId)) {
          index = idx
        }
      })

      if (index !== undefined && index < tableRows.length - 1) {
        configStore.setPickedProducts(tableRows[index + 1].contractId)
      }
    }

  }, [])


  // If no portfolio is picked, do not render anything
  if (getPortfolio === undefined) return null

  // Configurable if there are several product types to choose from.
  const configurable = getPortfolio.products?.length > 1

  const selector = configurable ? <select
    value={productId}
    onChange={e => {
      // Set product and then hide menu again
      setProductId(e.target.value); setSelectorOpen(false)
    }}
  >
    {getPortfolio.products.map(product => <option key={product} value={product}>{product}</option>)}
  </select> : null

  return (
    <div className="table-container">
      <TooltipText event={mouse} text={tooltip} />
      <table className="table">
        <thead>
          <tr>
            <td>
              <div className={'product-cell' + (selectorOpen ? ' expanded' : '')}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 'calc(var(--gap) / 2)', maxWidth: 'fit-content' }}>
                  <p>Product</p>
                  {configurable && <IoMdSettings style={{ cursor: 'pointer' }} onClick={() => {
                    setSelectorOpen(prev => !prev)
                  }}/>}
                </div>
                {selector}
              </div>
            </td>
            <td>Bid</td>
            <td>Ask</td>
            {showSpotPrices && <td>Spot</td>}
          </tr>
        </thead>
        <tbody>
          {tableRows
            .map((r) => {
              const isPicked = pickedProducts.some(p => r.contractId === p.contractId)
              const className = isPicked ? 'chosen clickable' : 'clickable'
              const hasBuy = hasStrategy(true, strategies, r, getPortfolio)
              const hasSell = hasStrategy(false, strategies, r, getPortfolio)
              return (
                <tr
                  key={r.name}
                  className={className}
                  onClick={chooseContract(r.contractId)}
                >
                  <td title={r.name}>{productToShortName(r)}</td>
                  <td
                    className={hasBuy ? 'has-buy' : ''}
                    onMouseEnter={(event: MouseEvent) => {
                      setMouse(event)
                      createTooltipText(r, true)
                    }}
                    onMouseMove={(event: MouseEvent) => {
                      setMouse(event)
                    }}
                    onMouseLeave={() => {
                      setMouse(undefined)
                      setTooltip(undefined)
                    }}
                  >
                    {formatPrice(r.state, r.bid, true)}
                  </td>
                  <td
                    className={hasSell ? 'has-sell' : ''}
                    onMouseEnter={(event: MouseEvent) => {
                      setMouse(event)
                      createTooltipText(r, false)
                    }}
                    onMouseMove={(event: MouseEvent) => {
                      setMouse(event)
                    }}
                    onMouseLeave={() => {
                      setMouse(undefined)
                      setTooltip(undefined)
                    }}
                  >
                    {formatPrice(r.state, r.ask, false)}
                  </td>
                  {showSpotPrices && (
                    <td>{getSpotPrice(getPortfolio?.eic, r.deliveryStart)}</td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
})

export default Table
